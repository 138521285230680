.modal-enter .modalContent {
  opacity: 0;
  transform: scale(0.9);
}
.modal-enter-active .modalContent {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s;
}
.modal-exit .modalContent {
  opacity: 1;
}
.modal-exit-active .modalContent {
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.2s;
}

.modalBox {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(6px);
  background: rgba(0, 0, 0, 0.445);
  z-index: 999;
}
.modalContent {
  z-index: 9999;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;

  background-color: #f4f9fd;
  box-shadow: 0px 3px 6px #0000003a;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

.enterPromocodeBox {
  width: 510px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  .loadingBox {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .infoBoxConsultation {
    position: relative;
    height: auto;
    padding: 10px;
    padding-top: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 95%;
    padding-left: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;
    // -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
    // -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
    // box-shadow: 0px 0px 4px 1px #3287cd69;
    .promocodeType {
      position: absolute;
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      font-family: "SF UI Display Medium", sans-serif;
      font-weight: 600;
      font-size: 15px;
      color: #3288cd;
    }
    .specialtyMapBox {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 7px;
      grid-row-gap: 7px;
      .specialtyBox {
        background: white;
        border-radius: 8px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #3287cd33;
        img {
          margin-bottom: 5px;
        }
        .specialtyTitle {
          font-family: "SF UI Display Medium", sans-serif;
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
  }
  .infoBox {
    position: relative;
    height: 80px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 10px;
    width: 95%;
    padding-left: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;
    // -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
    // -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
    // box-shadow: 0px 0px 4px 1px #3287cd69;
    .promocodeType {
      position: absolute;
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      text-align: center;
      font-family: "SF UI Display Medium", sans-serif;
      font-weight: 600;
      font-size: 15px;
      color: #3288cd;
    }
  }
  .emptyResultBox {
    position: relative;
    height: 80px;
    width: 85%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SF UI Display Medium", sans-serif;
    font-weight: 600;
    color: red;
    font-size: 16px;
    text-align: right;
  }
  .iconBox {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
    -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
    box-shadow: 0px 0px 4px 1px #3287cd69;
  }
  .promocodeForm {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .enterPromocode {
    font-family: "SF UI Display Medium", sans-serif;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 30px;
  }
  .promocodeInput {
    font-family: "SF UI Display Medium", sans-serif;
    font-weight: 600;
    font-size: 25px;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    width: 95%;
    border: none;
    background: #fff;
    margin: 5px;
    -webkit-box-shadow: 0px 0px 4px 1px #3287cd49;
    -moz-box-shadow: 0px 0px 4px 1px #3287cd49;
    box-shadow: 0px 0px 4px 1px #3287cd49;
    &:focus {
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
  }
  .btn {
    height: 40px;
    margin: 5px;
    width: 95%;
    span {
      font-size: 17px;
    }
  }
}

.purchaseBox {
  width: 600px;
  height: 700px;
  padding: 15px;
  padding-top: 25px;
  background: #fff;
  .purchaseSystem {
    width: 100%;
    height: 100%;
  }
}
.cardContainer {
  position: relative;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-auto-rows: min-content;
  grid-auto-columns: max-content;
  grid-row-gap: 20px;
  padding: 30px;
  padding-top: 55px;
  .loadingBox {
    min-height: 250px;
    min-width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardStepText {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 15px;
    font-family: "SF UI Display Medium", sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #3288cd;
  }
  // padding: 3px;
  .cardItem {
    width: 100%;
    height: 100%;
    max-width: 260px;
    max-height: 80px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      max-width: 100%;
    }
    display: flex;
    padding: 15px;
    // background: #f4f9fd;
    background: #fff;
    border-radius: 8px;
    .createMedCard {
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #07141f;
    }
    &:hover,
    &:focus {
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
      -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
      box-shadow: 0px 0px 4px 1px #3287cd69;
    }
    & > img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }
    .cardInfooContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .userName {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #07141f;
    }
    .userRelation {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #8aa0b1;
    }
  }
}

.bookingSuccessBox {
  width: 410px;
  height: 410px;
  padding: 10px;
  .btn {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
}
