.notificationWrapper {
  z-index: 99999;
  position: fixed;
  bottom: 50px;
  right: -300px;
  max-width: 300px;
}

.notification-item {
  border-radius: 8px;
  background: #f6f7fb;
  opacity: 1;
  overflow: hidden;
  min-height: 60px;
  width: 300px;
  position: relative;
  animation-name: slideLeft;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  border: none;
  @media screen and (max-width: 1024px) {
    animation-name: slideLeftResponsive;
  }
  .closeBox {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      // background: white;
    }
    .closeIcon {
      width: 12px;
      height: 12px;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &.exit {
    animation: slideRight 0.5s;
    animation-fill-mode: forwards;
  }
  .notificationMessage {
    margin-top: 10px;
    font-family: "SF UI Display Regular", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    border: none;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      padding: 10px;
    }
    line-height: 25px;
    padding: 10px;
    padding-top: 25px;
    padding-bottom: 20px;
    width: 100%;
    text-align: left;
    // mt
  }
  .bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
    opacity: 0.6;
  }
  &.success {
    box-shadow: 0 0 8px #3287cd71;
    .notificationMessage {
      color: #3288cd;
    }
    .bar {
      background: #3287cda6;
    }
  }
  &.error {
    box-shadow: 0 0 8px rgba(255, 0, 0, 0.473);
    .notificationMessage {
      color: red;
    }
    background-color: white;
    // border: 1px solid red;

    .bar {
      background: red;
    }
  }
}

@keyframes slideLeftResponsive {
  0% {
    opacity: 0.5;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    margin-left: -310px;
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0.5;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(-320px);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(-320px);
  }
  100% {
    // opacity: 0.5;
    opacity: 1;
    transform: translateX(20px);
  }
}
