.btn {
  position: relative;
  font-family: "SF UI Display Regular", sans-serif;
  width: 100%;
  max-width: 100%;
  height: 40px;
  //max-height: 300px;
  border-radius: 8px;
  border: 0;
  outline: unset;
  background: #3288cd;
  color: #ffffff;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }
  line-height: 17px;
  text-align: center;
  transition: all 0.1 ease-in-out;
  .spinnerBox {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
  }
  &:hover,
  &:focus {
    // border: #3288cd;
    -webkit-box-shadow: 0px 0px 4px 1px #3287cd69;
    -moz-box-shadow: 0px 0px 4px 1px #3287cd69;
    box-shadow: 0px 0px 4px 1px #3287cd69;
  }
  &:disabled:hover {
    box-shadow: none;
  }
  cursor: pointer;
  span {
    font-family: "SF UI Display Regular", sans-serif;
    font-weight: 500;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    align-items: center;
    margin: 0 auto;
  }
  & span > .selectedLabels {
    display: flex;
    flex-direction: column;
    padding: 5px;
    span {
      margin: 5px 0;
    }
  }

  &.freeTopSpace {
    margin-top: 30px;
  }

  &:disabled {
    background: #f6f7fb;
    color: #b9b9b9;
  }
  &.primary {
    @extend .btn;
    height: 50px;
  }
  &.secondaryRed {
    @extend .btn;
    width: 99%;
    margin: 0 auto;
    display: flex;
    padding: 14px;
    height: 100%;
    max-height: 100%;
    background: #f6f7fb;
    // color: #272d2d;
    color: #3288cd;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(255, 0, 0, 0.342);
    // border: 1px solid rgba(255, 0, 0, 0.541);
    height: 100%;
    &:hover,
    &:focus {
      border: 1px solid rgba(255, 0, 0, 0.486);
      -webkit-box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.238);
      -moz-box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.238);
      box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.238);
    }
    span {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: auto;
      max-width: 85%;
      color: red;
      // padding: 5px;
      img {
        margin-right: 10px;
        max-width: 34px;
      }
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
  }
  &.small {
    @extend .btn;
    height: 35px;
    max-width: 150px;
    span {
      margin: 0;
      width: 95%;
    }
  }
  &.secondary {
    @extend .btn;
    display: flex;
    padding: 14px;
    background: #f6f7fb;
    // color: #272d2d;
    color: #3288cd;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #3288cd;
    &:hover,
    &:focus {
      border: 1px solid #3288cd;
    }
    span {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: auto;
      max-width: 90%;
      // padding: 5px;
      img {
        margin-right: 10px;
        max-width: 34px;
      }
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
  }
  &.green {
    @extend .btn;
    background: #90ee90;
  }
  &.red {
    @extend .btn;
    background: #fd3845;
  }
  &.redOutlined {
    @extend .btn;
    color: #fd3845;
    background: #f6f7fb;
    border: 1px solid rgba(255, 0, 0, 0.342);
    &:hover,
    &:focus {
      border: 1px solid rgba(255, 0, 0, 0.486);
      -webkit-box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.238);
      -moz-box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.238);
      box-shadow: 0px 0px 4px 1px rgba(255, 0, 0, 0.238);
      color: #f82331;
    }
  }
  &.save {
    @extend .btn;
    display: flex;
    padding: 14px;
    background: #3288cd;
    color: white;
    justify-content: space-between;
    align-items: center;
    transition: all 0.15 ease-in-out;
    &:hover,
    &:focus {
      background: #3488e9;
    }
    &:focus {
      background: #3288cd;
    }
    span {
      display: flex;
      align-items: center;
      height: auto;
      margin: 0 auto;
      img {
        margin-right: 10px;
        max-width: 34px;
      }
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
  }
  &.secondaryLock {
    @extend .btn;
    display: flex;
    padding: 14px;
    color: #3288cd;
    border: 1px solid #3288cd;
    background: #f6f7fb;
    margin-left: 1px;
    justify-content: space-between;
    align-items: center;
    transition: all 0.15s ease-in-out;
    &:hover,
    &:focus {
      background: #3288cd;
      color: white;
    }
    &:focus {
      background: #3288cd;
      color: white;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      margin: 0 auto;
      img {
        margin-right: 10px;
        max-width: 34px;
      }
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
  }
  &.dangerAnimation {
    @extend .btn;
    // background: #fd3845;
    border: 1px solid rgba(255, 99, 71, 0.705);
    color: rgba(255, 99, 71, 0.712);
    width: 100%;
    transition: all 0.15s ease-in-out;
    background: #f6f7fb;
    max-width: 140px;
    height: 40px;
    margin: 0 auto;
    &:hover,
    &:focus {
      background: tomato;
      color: white;
    }
    &:focus {
      background: tomato;
      color: white;
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
      }
    }
  }
  &.danger {
    @extend .btn;
    background: #fd3845;
    width: 100%;
    max-width: 140px;
    height: 40px;
    margin: 0 auto;
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
    &:hover,
    &:focus {
      background: #f82331;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
      }
    }
  }
  .arrowRight {
    position: absolute;
    top: 28%;
    right: 10px;

    transform: translateY(-50%);
    transform: rotateZ(180deg);
  }
}
